import request from "@/utils/request"

// 面经最新列表
export function get4News(params) {
    return request({
        url: "/friend/interview/semiLogin/recent/list",
        method: "get",
        params: { ...params },
    })
}

// 面经最热列表
export function get4Hots(params) {
    return request({
        url: "/friend/interview/semiLogin/hot/list",
        method: "get",
        params: { ...params },
    })
}

// 查看面经详情
export function get4Detail(topicId) {
    return request({
        url: "/friend/interview/semiLogin/detail",
        method: "get",
        params: { topicId },
    })
}

// 面经收藏
export function saveTopic(topicId) {
    return request({
        url: "/friend/interview/collect",
        method: "post",
        data: { topicId },
    })
}

// 查看面经详情
export function get4Stores(params) {
    return request({
        url: "/friend/interview/collect/list",
        method: "get",
        params: { ...params },
    })
}

// 面经取消收藏
export function cancelTopic(topicId) {
    return request({
        url: "/friend/interview/cancel-collect",
        method: "post",
        data: { topicId },
    })
}

// 面经点赞
export function addZan(topicId) {
    return request({
        url: "/friend/interview/like",
        method: "post",
        data: { topicId },
    })
}

// 面经点赞
export function cancelZan(topicId) {
    return request({
        url: "/friend/interview/cancel-like",
        method: "post",
        data: { topicId },
    })
}

// 面经转发
export function shareTopic(topicId) {
    return request({
        url: "/friend/interview/forward",
        method: "post",
        data: { topicId },
    })
}

// 查看面经详情
export function get4Filters(type) {
    return request({
        url: "/friend/section/semiLogin/list",
        method: "get",
        params: { type },
    })
}

// 热门面经列表
export function getSideHot() {
    return request({
        url: "/friend/interview/semiLogin/top/list",
        method: "get",
        params: {},
    })
}

// 热门面经列表
export function getSideTopic(companyId) {
    return request({
        url: "/friend/interview/semiLogin/company/hot/list",
        method: "get",
        params: { companyId },
    })
}
// 保存面经
export function saveArticle(data) {
    return request({
        // url: "/friend/user/interview/save",
        url: "/friend/user/article/save",
        method: "post",
        data: data,
    })
}
// 获取用户发布的文章列表
export function getAllArticleList(params) {
    return request({
        // url: "/friend/user/interview/list",
        url: "/friend/user/article/list",
        method: "get",
        params: params,
    })
}
// 获取用户发布的文章列表
export function getArticleDetail(params) {
    return request({
        url: "/friend/user/interview/detail",
        method: "get",
        params: params,
    })
}
// 获取草稿列表
export function getDraftList(params) {
    return request({
        // url: "/friend/user/interview/draft/list",
        url: "/friend/user/article/draft/list",
        method: "get",
        params: params,
    })
}
// 修改非动态查阅权限
export function updateArticleVisibility(data) {
    return request({
        url: "/friend/user/interview/updateVisibility",
        method: "post",
        data: data,
    })
}
// 修改非动态查阅权限
export function updateDynamicVisibility(data) {
    return request({
        url: "/friend/user/dynamic/updateVisibility",
        method: "post",
        data: data,
    })
}
// 删除文章
export function deleteArticle(data) {
    return request({
        url: "/friend/user/article/delete",
        method: "post",
        data: data,
    })
}
// 查看数据统计
export function getArticleNum(params) {
    return request({
        url: "/friend/user/interview/count",
        method: "get",
        params: params,
    })
}
// 获取用户动态列表
export function getUserDynamicArticle(params) {
    return request({
        url: "/friend/user/semiLogin/getUserDynamicArticle",
        method: "get",
        params: params,
    })
}
