import request from "@/utils/request"

// 埋点上报接口
export function eventSave(data) {
    return request({
        url: "/friend/data/event/save",
        method: "post",
        data: data,
    })
}
