<template>
    <div class="ku-article-timeline">
        <div class="ku-timeline-item">
            <div class="ku-timeline-img" v-for="item in list" :key="item.imageUrl">
                <img :src="item.imageUrl" />
            </div>
        </div>
    </div>
</template>
<script>
import { getTimeline } from "@/api/timeline"
export default {
    name: "ku-article-timeline",
    data() {
        return {
            type: 0,
            list: [],
        }
    },
    created() {
        return this.getTimelineList()
    },
    methods: {
        getTimelineList() {
            return getTimeline({ type: this.type, deliverChannel: "2" }).then(resp => {
                this.list = resp.data
            })
        },
    },
}
</script>
<style lang="scss">
.ku-article-timeline {
    padding: 20px 24px 26px;
    background-color: #fff;
    border-radius: 10px;
    margin-left: 20px;
}
.ku-timeline-item {
    // width: 1200px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 90px;
    background-color: #fff;
}
.ku-timeline-img {
    img {
        width: 100%;
        height: auto;
    }
}
</style>
