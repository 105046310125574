<template>
	<div class="recommend-tag-component">
		<div class="tag-item green" v-if="status===0">
			已内推
		</div>
		<div class="tag-item green" v-if="status===2">
			已通过
		</div>
		<div class="tag-item orange" v-if="status===3">
			有更新
		</div>
		<div class="tag-item danger" v-if="status===1">
			已拒绝
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			status:{
				default:''
			}
		}
	}
</script>

<style lang="scss" scoped>
	.recommend-tag-component{
		.tag-item{
			width: 60px;
			height: 24px;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 2px;
			margin-left: 14px;
			font-weight: 400;
			font-size: 14px;
				&.danger{
					background: rgba(236,55,44,0.1);
					color: #EC372C;
				}
				&.green{
					background: rgba(36,198,139,0.1);
					color: #24C68B;
				}
				&.orange{
					background: rgba(246,89,4,0.1);
					color: #FF5C02;
				}
			}
		
	}
</style>