import Vue from "vue"
import Vuex from "vuex"
import user from "./modules/user"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    getters: {
        paySuccess: state => state.user.paySuccess,
        token: state => state.user.token,
        isShowLoginFrom: state => state.user.isShowLoginFrom,
        isShowRegistFrom: state => state.user.isShowRegistFrom,
        userInfo: state => state.user.userInfo,
        avatar: state => state.user.avatar,
        isWrite: state => state.user.isWrite,
        isHaveMassege: state => state.user.isHaveMassege,
        count: state => state.user.count,
        companyRecommendPostId: state => state.user.companyRecommendPostId,
    },
    mutations: {},
    actions: {},
    modules: { user },
})
