import request from "@/utils/request"

// 文章最新列表
export function getRecentList(params) {
    return request({
        url: "/friend/article/semiLogin/recent/list",
        method: "get",
        params: { ...params },
    })
}

// 文章最热列表
export function getHotList(params) {
    return request({
        url: "/friend/article/semiLogin/hot/list",
        method: "get",
        params: { ...params },
    })
}

// 文章收藏列表
export function getCollectList(params) {
    return request({
        url: "/friend/article/collect/list",
        method: "get",
        params: { ...params },
    })
}

// 热门文章列表
export function getTopList(params) {
    return request({
        url: "/friend/article/semiLogin/top/list",
        method: "get",
        params: { ...params },
    })
}

// 话题热帖列表
export function getTopicHotList(params) {
    return request({
        url: "/friend/article/semiLogin/topic/hot/list",
        method: "get",
        params: { ...params },
    })
}

// 文章详情
export function getArticleDetail(topicId) {
    return request({
        url: "/friend/article/semiLogin/detail",
        method: "get",
        params: { topicId },
    })
}

// 文章收藏
export function addArticleCollect(topicId) {
    return request({
        url: "/friend/article/collect",
        method: "post",
        data: { topicId },
    })
}

// 文章取消收藏
export function removeArticleCollect(topicId) {
    return request({
        url: "/friend/article/cancel-collect",
        method: "post",
        data: { topicId },
    })
}

// 文章收藏
export function addArticleLike(topicId) {
    return request({
        url: "/friend/article/like",
        method: "post",
        data: { topicId },
    })
}

// 文章收藏
export function removeArticleLike(topicId) {
    return request({
        url: "/friend/article/cancel-like",
        method: "post",
        data: { topicId },
    })
}

// 文章收藏
export function shareArtilce(topicId) {
    return request({
        url: "/friend/article/forward",
        method: "post",
        data: { topicId },
    })
}

// 文章详情
export function getTopicTypeList() {
    return request({
        url: "/friend/article/semiLogin/topic-type/list",
        method: "get",
        params: {},
    })
}

//
export function get4Filters(type) {
    return request({
        url: "/friend/section/semiLogin/list",
        method: "get",
        params: { type },
    })
}

// 通过topicType获取板块列表
export function getTopicTypes(topicType) {
    return request({
        url: "/friend/section/semiLogin/list/topic",
        method: "get",
        params: { topicType },
    })
}
// 获取动态列表的接口
export function getTrendList(params = {}) {
    return request({
        url: "/friend/user/dynamic/list",
        method: "get",
        params,
    })
}
// 发布动态的接口
export function publishTrend(params = {}) {
    return request({
        url: "/friend/user/dynamic/save",
        method: "post",
        data: params,
    })
}

// 内容数量统计
export function getArticleCount(params) {
    return request({
        url: "/friend/user/article/semiLogin/count",
        method: "get",
        params,
    })
}

// 获取当前用户某服务的剩余购买量
export function getRemainCount(serviceId) {
    return request({
        url: "/friend/order/job/remain/count",
        method: "get",
        params: { serviceId },
    })
}

// 避坑指南滚动滚动列表接口
export function getRefugeList(params={}) {
    return request({
        url: "/friend/article/roll/top-list",
        method: "get",
        params,
    })
}